import { StatusBar } from "expo-status-bar";
import React, { useState, useRef, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Button,
  List,
  Animated,
  Easing,
} from "react-native";
import tw from "tailwind-rn";

const ArchiveView = ({
  text,
  colors,
  darkMode,
  archiveView,
  setArchiveView,
  setDarkMode,
}) => {
  return (
    <View
      style={{
        ...tw(`flex-1 items-center justify-center relative`),
        backgroundColor: colors.bg,
      }}
    >
      <View style={tw("max-w-screen-md ")}>
        {text.map((p, i) => (
          <Text key={i} style={{ ...tw("text-2xl mb-8"), color: colors.fg }}>
            {p}
          </Text>
        ))}
      </View>

      <View style={tw("absolute bottom-0 left-0")}>
        <Button
          onPress={() => {
            setDarkMode(!darkMode);
            console.log("click button");
          }}
          title={darkMode ? "light" : "dark"}
        />
        <Button
          onPress={() => {
            setArchiveView(!archiveView);
          }}
          title={archiveView ? "write" : "archive"}
        />
      </View>
    </View>
  );
};

const themes = {
  dark: { bg: "hsl(223, 3%, 15%)", fg: "white", half: "hsl(223, 3%, 30%)" },
  light: { bg: "hsl(223, 3%, 95%)", fg: "black", half: "hsl(223, 3%, 75%)" },
};

const mockData = [
  // `Ok, so let me write some more stuff that I can use in a text that is already there... like a placeholder.. yeah. let me try that.. if i can get that to work it will be easier to develop this ui without having to sit and write tons of words... better to adjust it.`,
  // `and maybe some more stufff just for the sake of it.. i don't know if i will make any sense but at least it is fun,... ok. anywho...`,
];

const BlinkingCursor = ({ colors }) => {
  const [startValue, setStartValue] = useState(new Animated.Value(0));
  const [endValue, setEndValue] = useState(1);

  // remove blinking cursor?
  // stop backspaces
  // hmm

  // add sentiment analysis

  Animated.loop(
    Animated.spring(startValue, {
      toValue: endValue,
      friction: 1,
      useNativeDriver: true,
    }),
    { iterations: -1 }
  ).start();

  return (
    <Animated.Text
      style={{
        ...tw(`text-6xl font-bold mb-12`),
        color: colors.fg,
        opacity: startValue,
      }}
    >
      |
    </Animated.Text>
  );
};

export default function App() {
  const [text, setText] = useState(mockData);
  const [startValue, setStartValue] = useState(new Animated.Value(1));
  const [input, setInput] = useState("");
  const textInputElement = useRef(null);
  const [backspace, onBackspace] = useState(false);
  const [archiveView, setArchiveView] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const colors = themes[darkMode ? "dark" : "light"];
  const clearInput = () => {
    setText([...text, input]);
    setInput("");
    console.log("clearing input and setting text");
  };
  useEffect(() => {
    Animated.timing(startValue, {
      toValue: 0,
      duration: 3000,
      easing: Easing.bezier(0.07, 0.49, 0.2, 0.75),
      useNativeDriver: true,
    }).start();

    const timer = setTimeout(() => {
      clearInput();
    }, 3000);
    return () => clearTimeout(timer);
  }, [input]);

  if (archiveView)
    return (
      <ArchiveView
        text={text}
        colors={colors}
        archiveView={archiveView}
        darkMode={darkMode}
        setArchiveView={setArchiveView}
        setDarkMode={setDarkMode}
      />
    );

  const lastCharacterIsSpace = input.slice(-1) === " ";

  return (
    <View
      onClick={() => textInputElement.current.focus()}
      style={{
        ...tw(`flex-1 items-center justify-center cursor-text relative`),
        backgroundColor: colors.bg,
      }}
    >
      <Animated.Text
        style={{
          ...tw(`text-6xl font-bold mb-12`),
          color: lastCharacterIsSpace ? colors.half : colors.fg,
          opacity: startValue,
        }}
      >
        {/* {input.slice(-1)} */}
        {lastCharacterIsSpace ? "_" : input.slice(-1)}
      </Animated.Text>

      <View style={tw("absolute bottom-0 left-0")}>
        <Button
          onPress={() => {
            setDarkMode(!darkMode);
            console.log("click button");
          }}
          title={darkMode ? "light" : "dark"}
        />
        <Button
          onPress={() => {
            clearInput();
            setArchiveView(!archiveView);
          }}
          title={archiveView ? "write" : "archive"}
        />
      </View>

      <TextInput
        style={tw("opacity-0")}
        value={input}
        ref={textInputElement}
        onChangeText={(input) => {
          setInput(input);
        }}
        onKeyPress={(e) => {
          // console.log("key: ", e.nativeEvent.key);
          // if (e.nativeEvent.key === "Backspace") {
          //   e.preventDefault();
          //   console.log({ e });
          //   setInput(input + input.slice(-1));
          //   console.log("trying to prevent backspace");
          // } else {

          // }
          if (e.nativeEvent.key === "Enter") {
            clearInput();
            e.preventDefault();
            console.log("trying to prevent unfocus ");
          }
          startValue.stopAnimation((finalValue) => {
            const newStartValue = new Animated.Value(1);
            setStartValue(newStartValue);
            Animated.timing(newStartValue, {
              toValue: 0,
              duration: 3000,
              easing: Easing.bezier(0.07, 0.49, 0.2, 0.75),

              useNativeDriver: true,
            }).start();
          });
        }}
        autoFocus
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    cursor: "text",
    position: "relative",
  },
  character: {
    fontSize: "350px",
    minHeight: "350px",
  },
  input: {
    backgroundColor: "lightgrey",
    opacity: 0,
  },
  blinker: {
    position: "absolute",
    width: 20,
    height: 100,
    backgroundColor: "grey",
  },
});
